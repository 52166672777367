<template>
  <div class="site-container page-service-storage-custom">
    <ab-header></ab-header>

    <div class="site-banner">
      <img v-show="activeName === 0" src="@/assets/img/website/banner-service-storage.png" />   
      <img v-show="activeName === 1" src="@/assets/img/website/banner-service-custom.png" />   
    </div>

    <div class="site-category">
      <div
        class="item"
        :class="{ 'current': activeName === 0 }"
        @click="activeName = 0"
      >
        <i></i>
        <span>精益仓储</span>
      </div>
      <div
        class="item"
        :class="{ 'current': activeName === 1 }"
        @click="activeName = 1"
      >
        <i></i>
        <span>清关服务</span>
      </div>
    </div>

    <template v-if="activeName === 0">
      <div class="service-storage-pic"></div>

      <div class="site-module">
        <div class="sm-hd">10000㎡自营仓</div>
        <div class="sm-bd storage-mod-1">
          <div class="pic">
            <img src="@/assets/img/website/storage-mod-1.png" />
          </div>
          <div class="text">
            　　芯起国际物流运营海外仓、保税仓、完税仓面积10000多平方米。在长三角、珠三角、香港、新加坡、新疆、哈萨克斯坦等地设有自营仓库。为客户提供恒温恒湿的仓储、装卸、分拨、流通加工、配送以及条码管理、存仓分析等多元一体的未税、保税及完税的精益仓储服务，帮助客户有效降低库存风险，提高整体运营效率。<br />
            　　具备先进的仓储设备，并拥有成熟的管理系统与专业的操作团队，可以做到3小时分拣、24小时配送。
          </div>
        </div>
      </div>

      <div class="site-module">
        <div class="sm-hd">CKD级别仓库管理</div>
        <div class="sm-bd storage-mod-2">
          <div class="text">
            　　采用最高等级一CKD级别仓库管理，实现货物的快速入库、出库和调配，减少货物滞留时间，提高物流运作的效率。通过合理规划货物存放位置，减少货物的损坏和丢失，提高货物的完整性和质量，提高客户对物流服务的满意度。还可以通过及时更新货物信息，提供准确的货物跟踪和查询服务。
          </div>
          <div class="pic">
            <img src="@/assets/img/website/storage-mod-2.png" />
          </div>
        </div>
      </div>

      <div class="site-module">
        <div class="sm-hd">先进的仓储系统</div>
        <div class="sm-bd storage-mod-3">
          <div class="pic">
            <img src="@/assets/img/website/storage-mod-3.png" />
          </div>
          <div class="text">
            　　近年来投入数千万元，针对客户的运营需求，通过与湖南大学等高校的合作，利用先进的大数据、物联网、区块链等新一代信息技术，自主研发了面向客户服务的数字化系统，实现了订单全流程在线可视、可信、可控。可以准确实时掌握所需的仓库库存数据、运营数据，使货物存储与出入库更加智能化。
          </div>
        </div>
      </div>

      <div class="site-module">
        <div class="sm-hd">贴标签</div>
        <div class="sm-bd storage-mod-4">
          <div class="text">
            <b>制作标签</b><br />
            　　标签一般有3种：原厂标签，代理商标签，工厂标签。<br />
            　　线上制作：客户提供模板，IT制作模板导入系统，系统自动抓取对应的信息字段，货物出库时标签直接通过系统打印。
          </div>
          <div class="pic">
            <img src="@/assets/img/website/storage-mod-4.png" />
          </div>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="site-module">
        <div class="sm-hd">清关服务</div>
        <div class="sm-bd custom-mod-1">
          <div class="text">
            　　芯起现有关务团队18人，由海关背景资深人士带领，其中拥有10年以上关务工作经验者8人，均通过国家报关员资格考试并获得报关员资格证；其他人员中有3人取得关务中级证书，2人取得关务初级证书。是经中国报关协会授权的具备进出口商品归类服务资质的企业，目前HS编码归类团队成员5人，均持有国家归类师证，为客户提供合规、精准、高效的商品归类和咨询服务，协助客户解决商品归类争议，搭载具有自主知识产权的自动归类系统，加快清关速度，为企业构筑便利清关的桥梁。<br />
            　　关务团队现有人员工作经验覆盖北上广深等一线城市口岸且在当地有人脉资源。现有关务各有所长，精通各种贸易方式及保税区进出口操作，能快速根据货物特点及海关政策，提供定制化通关方案。查验零延迟，可以实现2小时极速通关。
          </div>
          <div class="pic">
            <img src="@/assets/img/website/custom-mod-1.png" />
          </div>
        </div>
      </div>
    </template>

    <ab-footer></ab-footer>
  </div>
</template>

<script>
export default {
  name: 'service-storage-custom',
  components: {

  },

  data () {
    return {
      activeName: 0
    }
  },

  computed: {

  },

  watch: {

  },

  mounted() {

  },

  methods: {
    
  }
}
</script>

<style lang="scss">
.page-service-storage-custom {
  .site-category .item {
    i {
      background-size: contain;
    }
    &:nth-child(1) {
      i {
        background-image: url("../../assets/img/website/service-storage.png");
      }
      &.current i {
        background-image: url("../../assets/img/website/service-storage-current.png");
      }
    }
    &:nth-child(2) {
      i {
        background-image: url("../../assets/img/website/service-custom.png");
      }
      &.current i {
        background-image: url("../../assets/img/website/service-custom-current.png");
      }
    }
  }
  .service-storage-pic {
    width: 1200px;
    height: 593px;
    margin: 60px auto 0;
    background: url("../../assets/img/website/service-storage-pic.png") no-repeat center;
    background-size: contain;
  }
  .storage-mod-1 {
    display: flex;
    justify-content: space-between;
    .pic {
      flex: none;
      width: 580px;
      img {
        width: 100%;
      }
    }
    .text {
      margin-left: 35px;
      font-size: 18px;
      line-height: 36px;
      color: #000;
    }
  }
  .storage-mod-2 {
    display: flex;
    justify-content: space-between;
    .pic {
      flex: none;
      width: 730px;
      img {
        width: 100%;
      }
    }
    .text {
      margin: 50px 35px 0 0;
      font-size: 18px;
      line-height: 36px;
      color: #000;
    }
  }
  .storage-mod-3 {
    display: flex;
    justify-content: space-between;
    .pic {
      flex: none;
      width: 500px;
      img {
        width: 100%;
      }
    }
    .text {
      margin-left: 110px;
      font-size: 18px;
      line-height: 36px;
      color: #000;
    }
  }
  .storage-mod-4 {
    display: flex;
    justify-content: space-between;
    .pic {
      flex: none;
      width: 510px;
      img {
        width: 100%;
      }
    }
    .text {
      margin: 30px 160px 0 0;
      font-size: 18px;
      line-height: 36px;
      color: #000;
    }
  }
  .custom-mod-1 {
    display: flex;
    justify-content: space-between;
    .pic {
      flex: none;
      width: 640px;
      img {
        width: 100%;
      }
    }
    .text {
      margin: 0 35px 0 0;
      font-size: 18px;
      line-height: 36px;
      color: #000;
    }
  }
}
</style>
